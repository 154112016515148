if (process.env.NODE_ENV === 'production') {
const sw = 'service-worker.js';

  if ('serviceWorker' in navigator) {
//    navigator.serviceWorker.register(new URL('service-worker.js', import.meta.url))
    navigator.serviceWorker.register(sw)
      .then(reg => {
        console.log('Service Worker успешно зарегистрирован:', reg);
      })
      .catch(err => {
        console.error('Ошибка при регистрации Service Worker:', err);
      });
  }
}
